const ynTripListTwo = [
    {
      name: "昆明丨丽江丨大理 6天5晚",
      prize: 498,
      linkName: "",
      typeName:'昆明丨丽江丨大理 6天5晚',
      type:'6天5晚',
      typeList:[{
          typeName:'6天5晚'
      }],
      smallImages:'http://dspcloud.spay365.com/98a1a4cdbae87f3b03041ad90a079e8036f27aeb.jpg',
      orderDetialImage:'http://dspcloud.spay365.com/f9d117d33f0b781522bfb6f4e9328d25ce646755.png',
      bannerList: [
        {
          imgUrl:
            "http://dspcloud.spay365.com/bca68f24d72e6da370b71e83e7a448db731a3d7f.png",
        },
        {
          imgUrl:
            "http://dspcloud.spay365.com/78be77f632240b45e5cdd0df73bf4451cb5b9f49.png",
        },
        {
          imgUrl:
            "http://dspcloud.spay365.com/76d702c45a17181faaa1d280ecfc1c00ff7431f8.png",
        },
        {
          imgUrl:
            "http://dspcloud.spay365.com/0e817ab6d547e59ef97b2d379d283a82db3df5a4.png",
        },
      ],
      detailImage: "http://dspcloud.spay365.com/37674936b5e372ad0b50c5d77af17701a2923e15.jpg",
      productNo:'yntrip'
    },
    {
      name: "昆明丨芒市丨瑞丽丨腾冲 6天5晚",
      prize: 498,
      linkName: "KMRT",
      type:'6天5晚',
      typeName:'昆明丨芒市丨瑞丽丨腾冲 6天5晚',
      productNo:'yntripmrt',
      smallImages:'http://dspcloud.spay365.com/0ab040885c3a53f60769b4ce873a367f9a8523be.jpg',
      typeList:[{
          typeName:'6天5晚'
      }],
      bannerList: [
        {
          imgUrl:
            "http://dspcloud.spay365.com/458d45d8580c2d9cd38ba565cf123c14ed409708.png",
        }, {
          imgUrl:
            "http://dspcloud.spay365.com/9defb9763f7065dc92f56fa14f43d34e30969047.png",
        }, {
          imgUrl:
            "http://dspcloud.spay365.com/b9eb325e13f85018c291102ada26e8b57243f47a.png",
        },{
          imgUrl:
            "http://dspcloud.spay365.com/4518295e442835ed8e9eea428f110b3a955e24ba.png",
        }
      ],
      detailImage: "http://dspcloud.spay365.com/2c0c11dca0a289ef2198e51944cd5c95a1563cb7.jpg",
    },
    {
      name: "昆明丨西双版纳 6天5晚",
      prize: 498,
      type:'6天5晚',
      linkName: "KMRT",
      typeName:'昆明丨西双版纳 6天5晚',
      productNo:'yntripx',
      typeList:[{
          typeName:'6天5晚'
      }],
      smallImages:'http://dspcloud.spay365.com/82f31095158b3f7a22c1c2fa80bdbc247be87288.jpg',
      bannerList: [
        {
          imgUrl:
            "http://dspcloud.spay365.com/92508b80bbc75afb0b9f08e2bf44e6408e2e2479.png",
        },
        {
          imgUrl:
            "http://dspcloud.spay365.com/78260406bd6f1db9516a1b10f7b5fe40050a91a3.png",
        },
        {
          imgUrl:
            "http://dspcloud.spay365.com/47c8a641a7d5701096e6d5d6eb799548842379db.png",
        },{
          imgUrl:
            "http://dspcloud.spay365.com/b986e1d4392b71b4fdf42bf4174abf9f36dbade8.png",
        }
      ],
      detailImage: "http://dspcloud.spay365.com/7dbd20012086750eef60bccfdbcde21bda1a8b13.jpg",
    }
  ]
  
  export default ynTripListTwo;
  //498元三款产品的