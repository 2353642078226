<template>
  <div class="ynTripWrap">
    <div class="ynTripTop">
      <div class="ynBanner">
        <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in bannerList" :key="'topImg' + index">
                <img class="bannerImage" :src="item.imgUrl"  alt="" />
              </div>
          
            </div>
            <div class="swiperpagination"></div>
          </div>
      </div>
      <!-- <div class="xsqg"></div> -->
      <div class="shopDetail">
        <div class="shopDetailTitle">{{tripName}}</div>
        <div class="saleDetail">
          <div>已售112344件</div>
          <div>剩余19件</div>
        </div>
      </div>
    </div>
    <div class="ynTripType">
      <div class="ynModuleTitle">选择旅游产品</div>
      <div class="allType">
        <div :class="index==selIndex?'sampleTypeSel':'sampleType'" v-for="(item,index) in tripList" :key="index" @click="changeTrip(index)">
          {{item.typeName}}
          <div class="hot" v-if="index==0"></div>
        </div>
      </div>
    </div>
    <div class="tripDetail">
      <div class="detailLine"></div>
      <div class="detailTitle">宝贝详情</div>
      <div class="detailLine"></div>
    </div>
    <div class="detailImage">
      <img :src="imgUrl" alt="" ref="image" />
    </div>
    <div class="kfImages">
      <img :src="kfImagesUrl" alt="">
    </div>
    <div style="height:1.2rem;"></div>
    <div class="ynTripBtns">
      <div class="kf" @click="scrollBottom"></div>
      <div class="btnsModule">  
        <div class="fx" @click="openShare">分享</div>
        <div class="msq" @click="goBuy">马上抢</div> 
      </div>
    </div>
    <div class="shareImageModule" v-if="shareModuleShow">
      <div class="shareImage">
        <img :src="shareImages" alt="" class="imagesShare">
      </div>
      <div class="shareBtn" @click="saveShareImage">关闭</div>
    </div>
  </div>
</template>
<style scoped>
.ynTripWrap {
  width: 7.5rem;
  min-height: 100vh;
  background: #f8f8f8;
}
.ynTripTop {
  width: 7.5rem;
  background: #ffffff;
}
.ynBanner {
  width: 7.5rem;
  height: 7.5rem;
  background: #ffffff;
}
.swiper-container{
  width: 7.5rem;
  height: 7.5rem;  
}
.bannerImage{
    display: block;
    width: 100%;
    height: 100%;
}
.xsqg {
  width: 7.5rem;
  height: 1.3rem;
  background: url(~@/assets/ynTrip/xsqg.png) no-repeat;
  background-size: 100% 100%;
}
.shopDetail {
  padding: 0.35rem 0.3rem;
}
.shopDetailTitle {
  font-size: 0.28rem;
  color: #333333;
  font-weight: bolder;
}
.saleDetail {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  margin-top: 0.45rem;
  font-size: 0.22rem;
  color: #a9a9a9;
}
.ynTripType {
  margin-top: 0.2rem;
  background: #ffffff;
}
.ynModuleTitle {
  font-size: 0.32rem;
  color: #333333;
  font-weight: bolder;
  padding: 0.25rem 0.3rem;
}
.allType {
  padding: 0 0.3rem;
  padding-bottom: 0.3rem;
  /* display: flex;
  display: -webkit-flex;
  flex-wrap: wrap; */
}
.sampleType {
  font-size: 0.24rem;
  font-weight: bolder;
  color: #a2a2a2;
  padding: 0.08rem 0.15rem;
  background: #f8f8f8;
  border-radius: 0.2rem;
  margin-bottom: 0.1rem;
  margin-right: 0.1rem;
  position: relative;
}
.sampleTypeSel{
  font-size: 0.24rem;
  font-weight: bolder;
  color: #fff;
  padding: 0.08rem 0.15rem;
  background: #f1a64a;
  border-radius: 0.2rem;
  margin-bottom: 0.1rem;
  margin-right: 0.1rem;
  position: relative;
}
.hot{
  position: absolute;
  top: -0.2rem;
  right: 0;
  z-index: 1;
  width: 0.46rem;
  height: 0.47rem;
  background: url(~@/assets/ynTrip/hot.png) no-repeat;
  background-size: 100% 100%;
}
.tripDetail {
  padding: 0.4rem 0;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}
.detailLine {
  width: 0.85rem;
  height: 0.02rem;
  background: #999999;
}
.kfImages{
  width: 7.5rem;
  height: 5rem;
}
.kfImages img{
  display: block;
  width: 100%;
  height: 100%;
}
.imagesShare{
    display:block;
    width: 100%;
    height: 100%;
}
.detailTitle {
  font-size: 0.24rem;
  color: #999999;
  margin: 0 0.19rem;
  font-weight: bolder;
}
.ynTripBtns {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 7.5rem;
  height: 1.2rem;
  background: #fff;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.kf {
  width: 0.44rem;
  height: 0.77rem;
  background: url(~@/assets/ynTrip/kf.png) no-repeat;
  background-size: 100% 100%;
  margin-left: 0.3rem;
}
.btnsModule {
  height: 1.2rem;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  margin-right: 0.3rem;
}
.msq {
  width: 1.85rem;
  height: 0.7rem;
  font-size: 0.32rem;
  font-weight: bolder;
  color: #ffffff;
  text-align: center;
  line-height: 0.7rem;
  border-radius: 0.35rem;
  background-image: linear-gradient(to right, #e9402d, #e74e44);
}
.fx {
  width: 1.85rem;
  height: 0.7rem;
  font-size: 0.32rem;
  font-weight: bolder;
  color: #ffffff;
  text-align: center;
  line-height: 0.7rem;
  border-radius: 0.35rem;
  background-image: linear-gradient(to right, #ee8d43, #f1aa4b);
  margin-right: 0.25rem;
}
.shareImageModule {
  position: fixed;
  width: 7.5rem;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.shareImage {
  width: 6.5rem;
  height: 9.86rem;
  background: url(~@/assets/ynTrip/shareImage.png) no-repeat;
  background-size: 100% 100%;
}
.shareBtn {
  width: 6rem;
  height: 0.85rem;
  margin: 0 auto;
  margin-top: 0.55rem;
  text-align: center;
  line-height: 0.85rem;
  font-size: 0.32rem;
  color: #f8f8f8;
  font-weight: bolder;
  border-radius: 0.42rem;
  background-image: linear-gradient(to right, #e9402d, #e74e44);
}
.detailImage {
  width: 7.5rem;
}
.detailImage img {
  display: block;
  width: 100%;
  height: auto;
}</style>
<script>
import ynTripList from '../../utils/ynShopTwo.js'
console.log(ynTripList)
export default {
  name: "",
  props: {},
  data() {
    return {
      shareModuleShow: false,
      imgUrl: require("@/assets/ynTrip/kjDetail.jpg"),
      shareImages:require('@/assets/ynTrip/shareImage.png'),
      kfImagesUrl:require('@/assets/ynTrip/kfImage.jpg'),
      tripList:ynTripList,
      bannerList:[],
      tripName:'',
      selIndex:0,
      currentSwiper:null,
      isScroll:true
    };
  },
  components: {},
  created() {
    this.tripName = this.tripList[0].name;
    this.bannerList = this.tripList[0].bannerList;
    this.imgUrl = this.tripList[0].detailImage;
    localStorage.setItem('backIndex',2)
  },
  mounted() {
    this.initSwipe();
    const {scroll} = this.$route.query;
    console.log(scroll)
    if(scroll){
      var that = this;
      this.$nextTick(()=>{   
        that.$refs.image.onload = () => {
          if(this.isScroll){
            that.isScroll = false;
            that.scrollBottom();
          }
         };
      })
    }
  },
  beforeMount() {},
  beforeDestroy() {},
  methods: {
    changeTrip(index){
      this.selIndex = index;
      this.tripName = this.tripList[index].name;
      this.bannerList ='';
      this.destroySwiper();
      this.bannerList = this.tripList[index].bannerList;
      this.imgUrl = this.tripList[index].detailImage;
      console.log(this.bannerList)
      this.$nextTick(()=>{
        this.initSwipe();
      })

    },
    scrollBottom() {
      window.scrollTo({
        top: 10000,
        behavior: "smooth",
      });
    },
    openShare() {
      this.shareModuleShow = true;
    },
    saveShareImage() {
      // var image = new Image();
      // image.setAttribute("crossOrigin", "anonymous");
      // image.onload = function() {
      //   var canvas = document.createElement("canvas");
      //   canvas.width = image.width;
      //   canvas.height = image.height;
      //   var context = canvas.getContext("2d");
      //   context.drawImage(image, 0, 0, image.width, image.height);
      //   var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据'
      //   var a = document.createElement("a"); // 生成一个a元素
      //   var event = new MouseEvent("click", {
      //     bubbles: true,
      //     cancelable: true,
      //     view: window,
      //   }); 
      //   a.download = '分享图片'; 
      //   a.href = url; 
      //   a.dispatchEvent(event);
      // };
      // image.src = this.shareImages;
      this.shareModuleShow = false;
    },
    goBuy(){
      let shopItem = JSON.stringify(this.tripList[this.selIndex])
       localStorage.setItem('selShopItem',shopItem)
       localStorage.setItem('backIndex',2)
       this.$router.push({path:'/h/n/orderSure',query:{}}) 
    },
    initSwipe(){
      var that = this;
      this.currentSwiper = new this.$Swiper(".swiper-container", {
        loop: true,
            autoplay: {
              delay: 3000,
              stopOnLastSlide: false,
              disableOnInteraction: false, //滑动之后还可以自动播放
            },
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true,
        resistanceRatio: 0,
         on:{
          click: function(){
         
          },
        },

      });
    },
      destroySwiper(){
        try {
          this.currentSwiper.destroy(true,false)
        }catch (e) {
          console.log("删除轮播")
        }
    },
  },
};
</script>
